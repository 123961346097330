import React, { useCallback, useEffect, useState } from 'react'

import useBounce from '../hooks/useBounce'

const Space = ({ r, c, board }) => {
    const hasColor = board.find(p => { 
      const parts = p.split('-')
      const space = [parts[0], parts[1]].join('-')
      console.log('space', space)
      if (space === `${r}-${c}`) {
        return true
      } else{
        return false
      }
    })
    const bgColor = hasColor ? hasColor.split('-')[2] : 'white'
    return <td>
        <div style={{ border: '1px solid black', backgroundColor: bgColor, height: 25, width: 25 }}></div>
    </td>
}

let listening = false
const Bounce = () => {
  const [joined, setJoined] = useState(false)

  const { leave, data, move, join } = useBounce()

  useEffect(() => {
    function handleKeyDown(e) {
        console.log('move', e.keyCode)
        move(e.keyCode)
    }

      document.addEventListener('keydown', handleKeyDown);
      listening = true

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [data, move]);

  const start = useCallback(() => {
    setJoined(true)
    join()
  },[data])

  const stop = useCallback(() => {
    setJoined(false)
    leave()
  },[data])

  if (!data) {
    return   <div>
    <button onClick={start}>Join</button>
    </div>
  }

  const board = Object.keys(data).map(uid => {
    return `${data[uid][0]}-${data[uid][1]}-${data[uid][2]}`
  })
  
  function createArray(n) {
    return Array.from({ length: n }, (_, i) => i + 1);
  }

  return (
  <div>
    <button onClick={start}>Join</button>
    <button onClick={stop}>Leave</button>
    <div>
        <table>
        {createArray(30).map((r, rIdx) => {
            return <tr>{createArray(30).map((c, cIdx) => {
                return <Space r={rIdx} c={cIdx} board={board} />
            })}</tr>
        })}
        </table>
    </div>
  </div>
  )

}

export default Bounce