import { useCallback, useEffect, useState } from 'react'
import { getDatabase, off, onValue, ref, set, remove } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import _get from 'lodash.get'

const SIZE = 30

function getRandomColor() {
  var letters = '0123456789ABCD';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 14)];
  }
  return color;
}

const MOVES = {
    37: (p) => [p[0], p[1] - 1],
    38: (p) => [p[0] - 1, p[1]],
    39: (p) => [p[0], p[1] + 1],
    40: (p) => [p[0] + 1, p[1]],
}

const isOpen = (p, data) => {
    if (p[0] < 0 || p[0] >= SIZE || p[1] < 0 || p[1] >= SIZE) {
      return false
    }
    let noConflict = true
    Object.values(data).forEach(v => {
        if (v[0] === p[0] && v[1] === p[1]) {
            noConflict = false
        }
      })
    return noConflict
}

const useBounce = () => {
  const uid = _get(getAuth(), 'currentUser.uid')
  console.log('uid', uid)
  const refPath = 'bounce'
  const db = getDatabase()
  let bounceRef
  const [data, setData] = useState([])
  const [color, setColor] = useState()

  console.log('color', color)

  useEffect(() => {
    const get = () => {
      bounceRef = ref(db, refPath)
      onValue(bounceRef, (item) => {
        setData(item.val())
      })
    }
    get()

    return () => {
      if (bounceRef) { // close the connection
        off(bounceRef)
      }
    }
  }, [])

  const leave = () => {
    remove(ref(db, `bounce/${uid}`))
  }

  const setValue = useCallback((value) => {
    console.log('setting value', value, 'at', `bounce/${uid}`)

    set(ref(db, `bounce/${uid}`), value)
  }, [uid, color])

  const move = useCallback((keyCode) => {
    if (!MOVES[keyCode]) {
      return
    }
    const current = data[uid]
    if (!current) {
      console.log('current position unknown')
      console.log('data', data)
    } else {
      const potentialMove = MOVES[keyCode](current)
      console.log('potential move', potentialMove)
      if (isOpen(potentialMove, data)) {
        setValue([...potentialMove, color])
      } else {
        console.log('requested move not open')
      }
    }
  }, [data, uid, color])

  const join = useCallback(() => {
    console.log('data', data)
    let foundOpen = false
    let p = [0,0]
    while (!foundOpen && data) {
      let noConflict = true
      Object.values(data).forEach(v => {
        if (v[0] === p[0] && v[1] === p[1]) {
            noConflict = false
        }
      })
      if (noConflict) {
        foundOpen = true
      } else {
        if (p[0] === SIZE - 1) {
          p = [0, p[1] + 1]
        } else {
          p[0] ++
        }
      }
    }
    const c = getRandomColor()
    setColor(c)
    setValue([...p, c])
  }, [data, color])

  return {
    join,
    move,
    leave,
    data,
    color
  }
}

export default useBounce
