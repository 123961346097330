import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import KofiButton from './Kofi'

const Footer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1em;
  font-size: 1.2em;
  strong {
    font-weight: 800;
  }
  div {
    padding-bottom: 15px;
    a {
      text-decoration: none;
    }
  }
`

export default () => (
  <Footer>
    <KofiButton />
    <div><Link to='/terms'>Terms of Service</Link></div>
    <div><Link to='/privacy'>Privacy Policy</Link></div>
    <div><Link to='/bounce'>B</Link></div>
    <div><strong>Copyright © 2023 Anonymous Dudes Writing Code</strong></div>
  </Footer>
)
